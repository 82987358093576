<template>
  <div>
    <tab-uba-wrapper>
      <v-alert class="mt-3" type="info">
        Caso identifique quaisquer inconsistências, por favor, entre em contato
        com a Unidade de Beneficiamento do Algodão.
      </v-alert>

      <p class="section-subtitle">
        Revise se os dados das prensas das UBAs selecionadas estão corretos.
      </p>

      <v-divider></v-divider>

      <div
        class="mt-3 mb-5 text-responsible tables-content"
        v-for="(processingUnit, index) in selectedProcessingUnits"
        :key="index"
      >
        <p class="section-subtitle primary-text--text">
          {{ index + 1 }}. Dados da UBA
        </p>

        <div class="fill table-uba-content">
          <table-uba :items="[processingUnit]" :showCategory="true" />
        </div>

        <p class="section-subtitle primary-text--text">
          {{ index + 1 }}. Dados das Prensas
        </p>
        <div
          v-if="processingUnit.prensas && processingUnit.prensas.length"
          class="fill "
          :style="
            !isMobile
              ? getStyleHeightByAmountOfItems(processingUnit.prensas.length)
              : ''
          "
        >
          <table-presses :items="processingUnit.prensas" />
        </div>
        <v-divider class="mt-4"></v-divider>
      </div>
    </tab-uba-wrapper>
  </div>
</template>

<script>
import TabUBAWrapper from "@/modules/invite-uba/views/components/new-invite/TabUBAWrapper";
import TableUBA from "@/modules/invite-uba/views/components/new-invite/TableUBA";
import TablePresses from "@/modules/invite-uba/views/components/new-invite/TablePresses";
import GridMixin from "@/modules/core/mixins/gridMixin";

import { mapGetters } from "vuex";

export default {
  name: "StepReviewPresses",

  components: {
    TabUbaWrapper: TabUBAWrapper,
    TableUba: TableUBA,
    TablePresses
  },

  mixins: [GridMixin],
  data: () => ({
    isMobile: window.innerWidth < 960 // Define o estado inicial
  }),

  computed: {
    ...mapGetters("inviteUba", {
      selectedProcessingUnits: "getNewInviteSelectedProcessingUnits"
    })
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 960; // Atualiza o estado ao redimensionar
    }
  },
  mounted() {
    // Adiciona o listener de resize
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    // Remove o listener de resize para evitar vazamentos de memória
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>

<style lang="scss" scoped>
.table-uba-content {
  height: 120px;
  overflow-y: auto;
}

@media screen and (max-width: 960px) {
  .tables-content {
    padding: 12px 0;
    height: max-content;
  }
  .table-uba-content {
    height: auto;
    margin: 12px 0;
  }
}
</style>

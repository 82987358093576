<template>
  <div>
    <tab-uba-wrapper>
      <v-alert class="mt-3" type="info">
        Caso a Unidade de Beneficiamento de Algodão esteja localizada dentro de
        uma Unidade Produtiva, é importante fazer este vínculo no SINDA, para
        que o processo de certificação de ambas possa ser unificado.
      </v-alert>

      <p class="section-subtitle">
        Unidades de Beneficiamento de Algodão selecionadas
      </p>

      <div
        class="fill table-uba-content"
        :style="
          getStyleHeightByAmountOfItems(newInviteSelectedProcessingUnitsLength)
        "
      >
        <selected-ubas :showCategory="true" />
      </div>

      <p class="section-subtitle">
        1. Selecione um Responsável para responder pelas Unidades de
        Beneficiamento do Algodão selecionadas
      </p>

      <div class="mb-3 button-content">
        <v-btn @click="openDialogResponsible()" color="primary"
          >Adicionar Responsável ABR-UBA</v-btn
        >
      </div>

      <p class="section-subtitle">
        Responsável(eis) selecionados para o ABR-UBA
      </p>

      <div
        class="fill table-uba-content"
        :style="
          getStyleHeightByAmountOfItems(newInviteSelectedResponsiblesLength)
        "
      >
        <selected-responsibles />
      </div>
    </tab-uba-wrapper>

    <dialog-invite-user
      :dialogActivate="dialogInviteUser"
      :fnValidateUser="validateUser"
      @onCloseDialog="closeDialogResponsible"
      @inviteUser="onConfirmInsertUser"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import TabUBAWrapper from "@/modules/invite-uba/views/components/new-invite/TabUBAWrapper";
import DialogInviteUser from "@/modules/invite/views/components/DialogInviteUser";
import SelectedUBAs from "@/modules/invite-uba/views/components/new-invite/SelectedUBAs.vue";
import gridMixin from "@/modules/core/mixins/gridMixin";
import SelectedResponsibles from "@/modules/invite-uba/views/components/new-invite/SelectedResponsibles";

export default {
  name: "StepDefineResponsible",

  components: {
    TabUbaWrapper: TabUBAWrapper,
    DialogInviteUser,
    SelectedUbas: SelectedUBAs,
    SelectedResponsibles
  },

  mixins: [gridMixin],

  computed: {
    ...mapGetters("inviteUba", {
      newInviteSelectedProcessingUnitsLength:
        "getNewInviteSelectedProcessingUnitsLength",
      newInviteSelectedResponsiblesLength:
        "getNewInviteSelectedResponsiblesLength",
      dialogInviteUser: "getNewInviteDialogResponsibles",
      newInviteSelectedProcessingUnitsIds:
        "getNewInviteSelectedProcessingUnitsIds"
    })
  },

  methods: {
    ...mapActions("inviteUba", [
      "addOrUpdateResponsibleToNewInvite",
      "openDialogResponsible",
      "closeDialogResponsible",
      "validateFormResponsibleUBA"
    ]),

    async onConfirmInsertUser(payload) {
      this.addOrUpdateResponsibleToNewInvite(payload.data);
      this.closeDialogResponsible();
    },

    async validateUser(form) {
      form.algodoeiras = this.newInviteSelectedProcessingUnitsIds;
      await this.validateFormResponsibleUBA(form);
    }
  }
};
</script>

<style lang="scss" scoped>
.table-uba-content {
  overflow-y: auto;
}
@media screen and (max-width: 960px) {
  .button-content {
    display: flex;
    justify-content: center;
  }
  .table-uba-content {
    height: auto !important;
    margin: 12px 0;
  }
}
</style>

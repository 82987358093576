<template>
  <div>
    <h1 class="mb-4 page-title text-responsible">Novo Convite</h1>

    <v-stepper v-model="currentStep">
      <v-stepper-header>
        <template v-for="(step, n) in steps">
          <v-stepper-step
            :key="step.step"
            :complete="currentStep > step.step"
            :step="step.step"
            color="#185080"
          >
            {{ step.label }}
          </v-stepper-step>
          <v-divider
            v-if="n < maxSteps - 1"
            :key="`step-divider-${step.step}`"
          ></v-divider>
        </template>
      </v-stepper-header>
      <v-stepper-items>
        <stepper-content
          v-if="currentStep === stepsEnum.SELECT_UBA"
          :step="stepsEnum.SELECT_UBA"
          :max="maxSteps"
          @onNext="nextStep"
          @onPrevious="previousStep"
        >
          <step-select-uba />
        </stepper-content>
        <stepper-content
          v-if="currentStep === stepsEnum.REVIEW_OWNER"
          :step="stepsEnum.REVIEW_OWNER"
          :max="maxSteps"
          :nextBtnDisabled="nextBtnDisabledStepReviewOwner"
          :showTooltip="nextBtnDisabledStepReviewOwner"
          :tooltipMsg="$t('text.productive_unit_without_owner')"
          @onNext="nextStep"
          @onPrevious="previousStep"
        >
          <step-review-owner />
        </stepper-content>
        <stepper-content
          v-if="currentStep === stepsEnum.REVIEW_PRESSES"
          :step="stepsEnum.REVIEW_PRESSES"
          :max="maxSteps"
          @onNext="nextStep"
          @onPrevious="previousStep"
        >
          <step-review-presses />
        </stepper-content>
        <stepper-content
          v-if="currentStep === stepsEnum.DEFINE_RESPONSIBLES"
          :step="stepsEnum.DEFINE_RESPONSIBLES"
          :max="maxSteps"
          @onNext="nextStep"
          @onPrevious="previousStep"
        >
          <step-define-responsible />
        </stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import StepperContent from "@/modules/core/views/components/StepperContent";
import StepSelectUBA from "@/modules/invite-uba/views/components/new-invite/StepSelectUBA";
import StepReviewOwner from "@/modules/invite-uba/views/components/new-invite/StepReviewOwner";
import StepReviewPresses from "@/modules/invite-uba/views/components/new-invite/StepReviewPresses";
import StepDefineResponsible from "@/modules/invite-uba/views/components/new-invite/StepDefineResponsible";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewInviteUBA",

  components: {
    StepperContent,
    StepSelectUba: StepSelectUBA,
    StepReviewOwner,
    StepReviewPresses,
    StepDefineResponsible
  },

  data: () => ({
    currentStep: 1,
    maxSteps: 4,
    stepsEnum: {
      SELECT_UBA: 1,
      REVIEW_OWNER: 2,
      REVIEW_PRESSES: 3,
      DEFINE_RESPONSIBLES: 4
    },
    steps: [
      {
        step: 1,
        label: "Selecionar UBAs"
      },
      {
        step: 2,
        label: "Revisar Proprietário(s)"
      },
      {
        step: 3,
        label: "Revisar Prensas"
      },
      {
        step: 4,
        label: "Definir Responsáveis ABR-UBA"
      }
    ]
  }),

  computed: {
    ...mapGetters("inviteUba", [
      "getNewInviteSelectedProcessingUnits",
      "getNewInviteSelectedProcessingUnitsLength",
      "getNewInviteSelectedResponsibles",
      "getNewInviteSelectedResponsiblesLength"
    ]),

    nextBtnDisabledStepReviewOwner() {
      if (!this.getNewInviteSelectedProcessingUnitsLength) {
        return true;
      }
      return this.getNewInviteSelectedProcessingUnits.some(
        selectedProcessingUnit => !selectedProcessingUnit.proprietarios.length
      );
    }
  },

  methods: {
    ...mapActions("inviteUba", ["sendInviteUba", "clearInviteUba"]),
    ...mapActions(["toggleSnackbar"]),

    nextStep(event) {
      if (this.currentStep === this.stepsEnum.SELECT_UBA) {
        if (this.getNewInviteSelectedProcessingUnitsLength === 0) {
          this.toggleSnackbar({
            text: "Por favor, selecione pelo menos um UBA!",
            type: "info"
          });
          return;
        }
      }

      if (this.currentStep === this.stepsEnum.DEFINE_RESPONSIBLES) {
        if (this.getNewInviteSelectedResponsiblesLength === 0) {
          this.toggleSnackbar({
            text: "Por favor, selecione pelo menos um responsável ABR-UBA!",
            type: "info"
          });
          return;
        }

        this.$confirmDialog.open(
          "Você tem certeza que deseja realizar este Convite para a(s) Unidade(s) de Beneficiamento de Algodão selecionada(s)?",
          this.sendInvites
        );

        return;
      }

      this.currentStep = event;
    },

    previousStep(step) {
      if (this.currentStep === this.stepsEnum.SELECT_UBA) {
        this.clearInviteUba();
        this.$router.push({ name: "invite-uba" });
        return;
      }
      this.currentStep = step;
    },

    async sendInvites() {
      try {
        const payload = {
          algodoeiras: this.getNewInviteSelectedProcessingUnits.map(
            processingUnits => processingUnits.id
          ),
          convidados: this.getNewInviteSelectedResponsibles
        };

        await this.sendInviteUba(payload);

        this.clearInviteUba();

        this.toggleSnackbar({
          text: "Convite enviado com sucesso!",
          type: "success"
        });

        this.$router.push({ name: "invite-uba" });
      } catch (error) {
        this.toggleSnackbar({
          text:
            (error?.response?.data?.detail || error?.data?.detail) ??
            this.$t("text.erro_interno"),
          type: "error"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-stepper__wrapper {
  width: 100% !important;
}

::v-deep .v-stepper__header {
  box-shadow: none;
  border-bottom: 1px solid #ccc;
}
</style>
